import React from 'react'
import {Button, Modal} from 'react-bootstrap'
import {Link} from 'gatsby'
import 'components/css/modal.css'
import {Span} from 'components'

function Sales() {
  return (
    <div className="modal-view">
      <Link to="/careers" state={{ modal: false }}>
        <Button className="close" id="close-button">
         <Span aria-hidden="true">x</Span>
        </Button>
      </Link>

      <h2>GRAPHIC DESIGNER</h2><hr/>
    <br/>
      <Modal.Title><h4>MINIMUM QUALIFICATIONS</h4></Modal.Title>
      <Modal.Body>
        <ul>
          <li>Bachelor's degree in Multimedia Arts, Graphic Design, Fine Arts, Media, Advertising and related fields</li>
          <li>Highly creative</li>
          <li>Strong attention to detail</li>
          <li>Social media savvy</li>
          <li>Can multitask and meet tight deadlines</li>
          <li>Ability to express his/her ideas through presentations</li>
          <li>Has a 'can do' attitude</li>
          <li>Ability to work in a fast-paced environment</li>
          <li>Knowledgeable in Adobe Creative Suite Software (UI, Motion Graphics is a plus)</li>
          <li>Experience in financial technology is an advantage</li>
          <li>Good time management skills</li>
          <li>Good communication skills</li>
          <li>Good research skills</li>
          <li>Able to work with minimal supervision</li>
        </ul>
      </Modal.Body>
      <a href="mailto: careers@digipay.ph" className="resume"><Button className="resume-button">Send Resume</Button></a>
    </div>
  )
}

export default Sales
